@import "../../../styles/mixin";

.MuiFormControl-fullWidth {
  .MuiSelect-root {
    padding: 9.5px;
  }
  .MuiSvgIcon-root {
    @include media-max-sm() {
      font-size: 1rem;
    }
  }
}
