// media queries mixins
@mixin media-sm {
  @media screen and (min-width: 600px) {
    @content;
  }
}

@mixin media-md {
  @media screen and (min-width: 960px) {
    @content;
  }
}

@mixin media-lg {
  @media screen and (min-width: 1280px) {
    @content;
  }
}

@mixin media-xl {
  @media screen and (min-width: 1920px) {
    @content;
  }
}

@mixin media-max-sm {
  @media screen and (max-width: 600px) {
    @content;
  }
}

@mixin media-max-md {
  @media screen and (max-width: 960px) {
    @content;
  }
}

@mixin media-max-lg {
  @media screen and (max-width: 1280px) {
    @content;
  }
}

@mixin media-max-xl {
  @media screen and (max-width: 1920px) {
    @content;
  }
}
