@import "../../../styles/variables";

.MuiSwitch-colorSecondary.Mui-checked {
  color: $clr-main;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: $clr-bg-dark;
}

.MuiSwitch-colorSecondary.Mui-checked:hover {
  background-color: transparent;
}
