@import "../../styles/variables";
@import "../../styles/mixin";

/*Footer
--------------------*/
.footer {
  position: relative;
  padding: 58px 0;
  color: #fff;
  background: linear-gradient(
    92.68deg,
    #6d28ff 0.13%,
    #8f36ff 27.52%,
    #4901e1 100%
  );
  @media (min-width: 1400px) {
    // padding: 25px;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    width: calc(100vw - 8px);
  }

  .MuiBox-root {
    max-width: 1200px;
    padding: 0;
    margin: 0 auto;
    align-items: flex-start;
    justify-content: space-between;
  }
  & > .MuiBox-root {
    @media (max-width: 767px) {
      display: flex;
      flex-flow: column-reverse;
      align-items: flex-start;
    }
  }
  .footer-logo {
    display: block;
    max-width: 331px;
    margin-bottom: 0;
    font-size: 0;
  }
  .footer-list {
    width: auto;
    text-align: right;
    // margin-left: 90px;
    // margin-bottom: 18px;
    &:first-child {
      margin-left: 0;
    }
    .footer-t {
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 1.1;
    }
    ul {
      padding: 0;
      li {
        display: inline-block;
        width: auto;
        padding: 0;
        margin-left: 24px;
        margin-bottom: 16px;
        font-size: 12px;
        line-height: 1.4;
        font-weight: bold;
        a {
          display: block;
          color: #fff;
        }
        @include media-max-sm() {
          display: block;
          margin: 0 0 20px;
          font-size: 12px;
        }
      }
    }
  }
  .copyright {
    margin-top: 26px;
    line-height: 1;
    font-size: 12px;
    text-align: right;
    font-weight: bold;
    @media (max-width: 767px) {
      display: none;
    }
    &.sp-copyright {
      display: none;
      @media (max-width: 767px) {
        display: block;
        .copyright {
          display: block;
          margin: 0;
          font-size: 13px;
          text-align: left;
        }
      }
    }
  }

  /*レスポンシブ*/
  @media (max-width: 1240px) {
    padding: 64px 32px;
  }

  @include media-max-md() {
    .footer-list {
      width: 100%;
      margin-left: 0;
    }
  }

  @media (max-width: 768px) {
    .footer-logo {
      margin-bottom: 20px;
    }
  }

  @include media-max-sm() {
    padding: 48px 15px 55px;
    background: linear-gradient(
      140.17deg,
      #6d28ff 0%,
      #8f36ff 67.26%,
      #4901e1 100%
    );

    .footer-logo {
      max-width: 190px;
      margin-top: 40px;
      margin-bottom: 26px;
    }
    .footer-list {
      margin-left: 0;
      // margin-bottom: 20px;
      text-align: left;

      .footer-t {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 1.1;
      }

      li {
        margin: 0 0 12px;
        font-size: 14px;
      }
    }

    .copyright {
      margin-top: 30px;
      font-size: 15px;
      text-align: center;
    }
  }
}
