html,
body,
h1,
h2,
h3,
h4,
h5,
h6 a,
p,
span,
em,
small,
strong,
sub,
sup,
mark,
del,
ins,
strike,
abbr,
dfn,
blockquote,
q,
cite,
code,
pre,
ol,
ul,
li,
dl,
dt,
dd,
div,
section,
article,
main,
aside,
nav,
header,
hgroup,
footer,
img,
figure,
figcaption,
address,
time,
audio,
video,
canvas,
iframe,
details,
summary,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
}
body {
  font-size: 16px;
  line-height: 1.6;
  color: $clr-main;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

img {
  max-height: 100%;
  max-width: 100%;
  vertical-align: bottom;
}

ul,
ol,
li {
  list-style: none;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
td,
th {
  padding: 0;
}
video,
iframe {
  max-width: 100%;
  border: none;
}

input,
button,
select,
textarea {
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  border-radius: 0;
  -webkit-appearance: none;
  cursor: pointer;
}

input[type="checkbox"],
input[type="radio"],
input[type="file"] {
  display: none;
}
