$clr-accent: linear-gradient(225deg, #2400ff 0%, #8f00ff 100%);
$clr-main: #333333;
$clr-bg-dark: #9d9d9d;
$clr-bg-light: #eeeeee;
$clr-bg-base: #ffffff;
$clr-danger: #da0c3d;
$clr-purple: #8f00ff;
$clr-bg-search: #f9f9f9;

$font-paragraph: "Noto Sans JP", sans-serif;
