// global style
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: $font-paragraph;
  font-size: 16px;
  font-weight: 400;
}

h1 {
  font-family: $font-paragraph;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: center;
}

a {
  text-decoration: none;
  color: $clr-bg-dark;
}

img,
video {
  pointer-events: none;
}

/*header SP*/
#root .MuiContainer-root {
  @include media-max-sm() {
    padding-left: 10px;
    padding-right: 10px;

    .MuiToolbar-regular {
      min-height: 40px;
      padding-left: 0;
      padding-right: 0;

      a {
        font-size: 0;

        img {
          width: 168px;

          @media (max-width: 348px) {
            width: 140px;
            padding-top: 2px;
          }
        }
      }

      .makeStyles-HeaderPanel-10 {
        display: flex;

        .MuiIconButton-root {
          padding: 0;
          margin-left: 4px;
          &:first-child {
            width: 15px;
          }
          .MuiIconButton-label {
            font-size: 12px;
            font-weight: bold;
          }
        }

        .MuiIcon-root {
          width: 20px;
          height: 20px;
          font-size: 20px;
        }

        a {
          display: flex;
          width: 50px;
          height: 24px;
          padding: 0;
          margin: 4px;
          font-size: 12px;
          align-items: center;
          justify-content: center;
          line-height: 1;

          .MuiButton-label {
            padding-top: 2px;
            font-size: 12px;
            line-height: 1;
          }
        }
      }
    }
  }
}
.MuiAppBar-positionFixed {
  .MuiIcon-root {
    // width: 20px;
    // height: 20px;
    // font-size: 20px;
    height: 40px;
    font-size: 30px;
  }
  .MuiBottomNavigationAction-root {
    min-width: 0;
    padding: 8px 0;
  }
  .MuiBottomNavigationAction-label {
    font-size: 12px;
  }
}

#root .Mui-noroot {
  padding: 0;
}
header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionFixed {
  display: none;
  z-index: 99999;
}

// Input disabled時のアンダーライン
.MuiInput-underline.Mui-disabled:before,
.MuiFilledInput-underline.Mui-disabled:before {
  border: 1px solid $clr-main !important;
  border-width: 0 0 1px !important;
}

.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-weight: 400 !important;
}

// SP時のスティッキーボタン
//ボタン１つ
.stickyBtn {
  @media (max-width: 767px) {
    position: fixed;
    display: flex;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background: #fff;
    border-radius: 0;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }
  .MuiButtonBase-root {
    @media (max-width: 767px) {
      width: 95%;
      display: flex;
      padding: 8px 4px;
      z-index: 100;
      align-items: center;
      justify-content: center;
      .MuiButton-label {
        font-size: 16px !important;
      }
    }
  }
  &.grt_bg {
    background: linear-gradient(
      92.68deg,
      #6d28ff 0.13%,
      #8f36ff 27.52%,
      #4901e1 100%
    );
  }
}
//ボタン２つ
.sticky-2Btns {
  @media (max-width: 960px) {
    .MuiButton-label {
      font-size: 16px;
    }
  }
  @media (max-width: 767px) {
    position: fixed;
    display: flex;
    top: auto;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 10px;
    margin-top: 0;
    background: #fff;
    justify-content: space-between;
    z-index: 100;
    .MuiButtonBase-root {
      width: 49%;
    }
    .MuiButton-text {
      padding: 4px;
    }
  }
  @media (max-width: 360px) {
    .MuiButton-label {
      font-size: 14px;
    }
  }
  @media (max-width: 326px) {
    .MuiButton-label {
      font-size: 13px;
    }
  }
}
//ボタン３つ
@media (max-width: 1068px) {
  .header3Btns {
    display: block !important;

    .sticky-2Btns {
      text-align: end;
      margin-top: 20px;
    }
  }
}
.sticky-3Btns {
  @media (max-width: 1280px) {
    .MuiButton-label {
      font-size: 16px;
    }
  }
  @media (max-width: 1068px) {
    text-align: end;
    margin-top: 20px;
  }
  @media (max-width: 767px) {
    margin-top: 0;
    position: fixed;
    top: auto;
    right: 2.5%;
    width: 95%;
    bottom: 80px;
    display: flex;
    z-index: 100;
    position: fixed;
    justify-content: space-between;
    .MuiButtonBase-root {
      width: 49%;
    }
    .MuiButton-text {
      padding: 4px;
    }
  }
  @media (max-width: 360px) {
    .MuiButton-label {
      font-size: 14px;
    }
  }
  @media (max-width: 326px) {
    .MuiButton-label {
      font-size: 13px;
    }
  }
}

.MuiRadio-colorSecondary.Mui-checked.custom-radio-root:hover {
  background: transparent;
}

.MuiButtonBase-root.MuiButtonBase-root.MuiRadio-root:hover {
  background: transparent;
}

.MuiFormControl-root textarea {
  color: #000 !important;
}
.MuiInputBase-root input {
  color: #000 !important;
}
#mui-component-select-accountType,
div[aria-labelledby="mui-component-select-accountType"],
.MuiFormControl-root .MuiSelect-select {
  color: #000 !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: inherit !important;
}

.grt_bg .MuiButton-root {
  min-width: 206px;
  font-size: 20px;
  // background: linear-gradient(92.68deg, #6D28FF 0.13%, #8F36FF 27.52%, #4901E1 100%);
  background: linear-gradient(32deg, #692af0, #692af0);
  border-color: transparent;
  box-sizing: border-box;
  transition: 0.3s;
}
.grt_bg .MuiButton-root:hover {
  color: #fff;
  background: linear-gradient(32deg, #8f00ff, #5a2dda);
  // opacity: 0.8;
}
.grt_bg .MuiButton-startIcon {
  margin-right: 10px;
}
.grt_bg .MuiButton-root:disabled {
  color: #f7f7f7;
  background: #d1cfcf;
}

.MuiButtonBase-root.Mui-checked .MuiSvgIcon-root {
  color: #5a2dda !important;
}

header .MuiToolbar-regular {
  display: flex;
  min-height: 120px;
  justify-content: space-between;
  @media (max-width: 960px) {
    padding: 0 10px;
    @media (max-width: 784px) {
      min-height: 80px;
    }
  }
}

header .logo-box {
  display: flex;
  align-items: center;
  @media (max-width: 934px) {
    a:first-child {
      max-width: 260px;
    }
  }
  @media (max-width: 784px) {
    a:first-child {
      max-width: 200px;
    }
  }
  @media (max-width: 600px) {
    padding-top: 10px;
  }
}
header .logo-box .fo-about {
  margin-top: 3px;
  margin-left: 10px;
  font-size: 13px;
  font-weight: bold;
  color: #000;
}

.MuiPopover-root .MuiPopover-paper .MuiButton-root {
  border: none;
}

.mypage-bg {
  background-color: #efefef;
  @media (max-width: 767px) {
    margin-bottom: 50px !important;
  }
}

.header_btns {
  @media (max-width: 479px) {
    margin-right: -10px;
  }
}

.marketOwner {
  .market-main {
    @media (max-width: 600px) {
      padding-top: 20px !important;
    }
  }
}

div[role="presentation"] {
  .MuiTypography-h4 {
    font-size: 30px;
  }
  .MuiTypography-h3 {
    font-size: 44px;
  }
  .MuiPickerDTToolbar-separator {
    margin: 0 0 2px;
  }
  .MuiPickersToolbarButton-toolbarBtn {
    .MuiButton-label {
      font-size: 16px;
      .MuiTypography-subtitle1 {
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }
  @media (max-width: 340px) {
    .MuiToolbar-regular {
      height: 80px;
      min-height: 48px;
    }
    .MuiPickersBasePicker-pickerView {
      max-width: 100%;
      min-width: 100%;
    }
    .MuiTypography-h3 {
      font-size: 36px;
    }
  }
}

#customized-menu {
  ul {
    li {
      a {
        display: flex;
        color: inherit;
        align-items: center;
      }
      svg,
      span {
        margin-right: 2px;
        margin-top: 0;
      }
    }
  }
}

.stickyBtn {
  border-radius: 30px;
  @media (max-width: 767px) {
    border-radius: 0;
  }
}

.MuiTypography-h1 {
  word-break: break-word;
}

.rinbonNote {
  padding: 8px 0;
  margin: 10px 0;
  font-size: 14px;
  text-align: center;
  color: #fff;
  background: #333;
}

.items-btn {
  & + .item__container {
    .common-layout {
      // padding: 0;
    }
  }
}

.confirmation {
  .Mui-disabled {
    &::before {
      border-color: transparent !important;
    }
    .MuiSelect-icon {
      display: none;
    }
    #mui-component-select-quantity {
      margin-top: 14px;
    }
  }
  .MuiFormControl-root {
    textarea {
      background: #fff;
      border-color: transparent !important;
    }
  }
  .compercent {
    .MuiFormControl-fullWidth {
      position: relative;
      &::after {
        content: "%";
        position: absolute;
        display: inline-block;
        top: 23px;
        left: 44px;
        width: 20px;
      }
    }
    .MuiInputBase-root {
      width: auto;
    }
    .MuiInputBase-input.Mui-disabled {
      display: inline-block;
      width: auto;
      min-width: auto;
    }
  }
}

.tab-list-inner {
  display: flex;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
  @media (max-width: 767px) {
    margin: 10px 0 25px;
  }
  & > div {
    width: 100%;
    padding: 14px 12px;
    margin-bottom: 10px;
    font-weight: bold;
    line-height: 1.3;
    text-align: center;
    border-bottom: 2px solid transparent;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      border-color: #eee;
    }
    &.active {
      border-color: #333;
    }
  }
}

.text-editor-preview {
  list-style-position: inside;
  word-wrap: break-word;
  li {
    list-style: inherit;
  }
  ul {
    padding-left: 1.2em;
    list-style: disc;
  }
  ol {
    padding-left: 1em;
    list-style: decimal;
  }
}

.complete-items {
  .collection-btn {
    display: flex;
    padding-top: 20px;
    justify-content: flex-end;
    @media (max-width: 767px) {
      width: 100%;
      padding-top: 0;
    }
    .MuiButton-label {
      text-decoration: none;
    }
    a:hover {
      text-decoration: none;
    }
  }
}

.MuiFormControl-root[format="yyyy/MM/dd HH:mm"] .MuiInputBase-root {
  font-size: 1.07rem;
}

.item-sidemenu {
  width: 50%;
  margin-right: 40px;
  @media (max-width: 766px) {
    display: none;
  }
}
.item-maincontent {
  width: calc(50% - 40px);
  @media (max-width: 766px) {
    width: 100%;
  }
}
.lit-image > .lit-s-image {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  pointer-events: none;
  &::before {
    // content: "";
    display: block;
    width: 100%;
    padding-top: 100%;
  }
  img,
  video {
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    height: 100%;
    vertical-align: bottom;
    object-fit: contain;
    pointer-events: auto;
  }
}
.lt-serial {
  display: block;
  padding: 24px 0;
  font-size: 14px;
  color: #9d9d9d;
  @media (max-width: 766px) {
    padding: 15px 0 20px;
  }
  span {
    margin-right: 28px;
  }
}
.simple-box-layout {
  margin-bottom: 30px;
  border: 1px solid #dedede;
  border-radius: 16px;
  overflow: hidden;

  .sim-b-title {
    display: flex;
    padding: 20px 18px 14px;
    font-size: 14px;
    line-height: 1.7;
    border-bottom: 1px solid #dedede;
    font-weight: bold;
    align-items: center;
    span {
      display: block;
      width: 24px;
      margin-right: 7px;
      font-size: 0;
    }
  }

  .gradient-bg {
    background: linear-gradient(45deg, #7401ff 0%, #3e00ff 100%);
    color: #fff;
  }

  .MuiAccordion-rounded {
    padding: 0 !important;
    .MuiAccordionDetails-root {
      margin-top: 0;
      .MuiGrid-container {
        margin-top: 0;
      }
    }
    .MuiAccordionSummary-root {
      display: none;
    }
  }
  .MuiTableCell-head {
    height: 48px;
    padding: 0 0 0 20px;
    font-size: 14px;
    border-color: #dedede;
  }
  .MuiTableCell-root,
  td {
    height: 64px;
    padding: 0 0 0 20px;
    font-size: 14px;
    border-bottom: 1px solid #dedede;
  }
  tbody {
    .MuiTableRow-root {
      &:last-child {
        .MuiTableCell-root {
          border-bottom: none;
        }
        .token-data {
          border-bottom: none;
        }
      }
    }
  }
  .MuiPaper-rounded {
    border: none !important;
  }

  .sim-inner {
    padding: 24px 28px;
    @media (max-width: 766px) {
      padding: 23px 10px 40px;
    }
    .MuiBox-root {
      margin: 0 !important;
      font-size: 14px;
      color: #333;
      line-height: 2;
    }
    &.precutions-inner {
      .MuiPaper-root {
        .MuiCollapse-wrapperInner {
          .MuiBox-root {
            padding: 0;
            border: none;
            border-radius: 0;
            &::-webkit-scrollbar {
              -webkit-appearance: none;
              width: 4px;
              background: rgba(0, 0, 0, 0.05);
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 4px;
              background: #dedede;
              box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
            }
            h2,
            p,
            li,
            a {
              font-size: 14px;
              font-weight: normal;
              color: #333;
              line-height: 2;
            }
            h2 {
              &:not(:first-child) {
                margin-top: 26px;
              }
            }
          }
        }
      }
    }
  }
  .MuiAccordion-root::before {
    content: none;
  }
  &.content-simbox {
    .MuiAccordion-rounded {
      .MuiAccordionSummary-root {
        display: block;
        padding-top: 16px;
        margin: 20px 0 16px;
        border-top: 1px solid #dedede;
        pointer-events: none;
        .accordion-title {
          font-size: 18px;
          line-height: 1.4;
        }
        .MuiIconButton-root {
          display: none;
        }
      }
    }
  }
}

.item-maincontent {
  .item-connection__list {
    display: none;
    @media (max-width: 766px) {
      display: block;
    }
  }
  .myaribbon {
    margin-top: 0 !important;
  }
}

.sim-box-sp {
  display: none;
  @media (max-width: 766px) {
    display: block;
  }
}

.MuiDrawer-modal {
  .MuiDrawer-paper {
    .MuiGrid-item {
      & > .MuiBox-root {
        background: #f7f7f7;
        & > .MuiBox-root {
          padding: 0 20px 20px;
          &:first-child {
            display: block;
            padding: 20px 20px 0;
            height: 77px;
            color: #000;
            font-size: 26px;
            font-family: futura-pt, sans-serif;
            font-weight: 600;
            font-style: italic;
            background: none;
            .MuiIcon-root {
              font-size: 24px;
              color: #000;
            }
            & > .MuiButtonBase-root {
              top: 18px;
              right: 18px;
            }
          }
          &:nth-child(2) {
            & > .MuiBox-root {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}
.MuiCheckbox-root {
  margin-right: 15px !important;
  & + .MuiTypography-root {
    padding-bottom: 2px;
  }
}
.MuiCheckbox-colorSecondary {
  .MuiIconButton-label {
    .MuiSvgIcon-root {
      background: #dedede;
      path {
        color: transparent;
      }
    }
    input {
      &:checked {
        & + .MuiSvgIcon-root {
          path {
            color: currentColor;
          }
        }
      }
    }
  }
}

.simple-box-layout {
  .nftuid-table {
    word-break: break-all;
    .table-container {
      min-width: 100%;
      table {
        min-width: 430px;
        @media (max-width: 766px) {
          min-width: 400px;
        }
      }
      th {
        height: 48px;
        @media (max-width: 766px) {
          width: 100px;
        }
        &:first-child {
          width: 180px;
        }
        &:nth-child(3) {
          width: 140px;
          text-align: center;
        }
        &:last-child {
          width: 210px;
          padding: 0 20px;
          text-align: center;
        }
      }
      td {
        &:last-child {
          padding: 0 20px;
          .pro-status {
            display: flex;
            align-items: center;
            justify-content: center;
            &.primary .MuiButtonBase-root {
              pointer-events: none;
            }
            &.secondary .MuiButtonBase-root {
              background: #9d9d9d;
              border-color: #9d9d9d;
              color: #f7f7f7;
            }
            .MuiButtonBase-root {
              display: flex;
              width: 80px;
              min-width: 100px;
              max-width: 130px;
              height: 32px;
              padding: 0;
              margin: 0;
              align-items: center;
              justify-content: center;
              // pointer-events: none;
              .MuiButton-label {
                font-size: 14px;
              }
              &:hover {
                color: #fff;
                background: #692af0;
                border-color: #692af0;
              }
              & + .MuiBox-root {
                margin-left: 8px;
                margin-right: -32px;
                font-size: 0;
                cursor: pointer;
                @media (max-width: 766px) {
                  margin-left: 7px;
                  margin-right: -31px;
                }
              }
            }
            &.nodata {
              .MuiButtonBase-root {
                color: #000;
                background: #fff;
                pointer-events: none;
              }
            }
          }
        }
        img {
          margin-right: 8px;
        }
        & > .MuiBox-root {
          display: flex;
          line-height: 1;
          align-items: center;
        }
      }
    }
  }
}
.token-modal {
  .MuiDialog-paperScrollPaper {
    border-radius: 16px;
    overflow: hidden;
  }
  .MuiDialogTitle-root {
    padding: 0;
    & > .MuiTypography-root {
      display: flex;
      height: 64px;
      padding: 10px 20px;
      background: #f7f7f7;
      border-bottom: 1px solid #dedede;
      align-items: center;
      justify-content: space-between;
      & > .MuiBox-root {
        display: flex;
        align-items: center;
        .MuiTypography-root {
          margin-right: 20px;
          font-size: 14px;
          img {
            margin-right: 8px;
          }
        }
      }
    }
  }
  .MuiDialogContent-root {
    padding: 0;
    & > .MuiGrid-container {
      padding: 0;
      .MuiTable-root {
        position: relative;
        .MuiTableRow-root {
          width: 100%;
        }
      }
      .MuiTableHead-root {
        display: block;
        width: 100%;
        padding: 0 27px 0 20px;
        background: #f7f7f7;
        border-bottom: 1px solid #dedede;
        th {
          height: 45px;
          padding: 0 20px;
          font-size: 12px;
          background: #f7f7f7;
          border: none;
          &:first-child {
            padding-left: 0;
          }
          &:first-child,
          &:nth-child(2) {
            width: 15%;
          }
          &:nth-child(3),
          &:nth-child(4) {
            width: 20%;
          }
          &:nth-child(5) {
            width: 99999px;
          }
        }
      }
      .MuiTableBody-root {
        display: block;
        width: 100%;
        height: 268px;
        padding: 0 20px 20px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
        td {
          height: 68px;
          font-size: 14px;
          font-weight: bold;
          &:first-child {
            padding-left: 0;
          }
          &:first-child,
          &:nth-child(2) {
            width: 15%;
          }
          &:nth-child(3),
          &:nth-child(4) {
            width: 20%;
          }
          &:nth-child(5) {
            width: 99999px;
          }
        }
      }
      .table-container {
        min-width: 648px;
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}

.RibbonMessageBox {
  .material-icons {
    margin-right: 10px;
  }
}

.common-layout {
  .market-main {
    .title {
      margin: 2px 0 8px;
    }
    .price-wrap {
      .label {
        .price {
          margin-right: 8px;
          font-size: 22px;
          font-weight: normal;
          letter-spacing: -0.5px;
          font-family: "Helvetica Neue", Helvetica, Arial, Verdana, Roboto,
            "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic",
            "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo UI",
            "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
        }
        .MuiChip-root {
          height: auto;
          padding-top: 3px;
          border: none;
          .MuiChip-label {
            padding: 0;
            font-size: 10px;
            line-height: 1;
            font-weight: bold;
            text-align: center;
          }
        }
      }
    }
    .met_tags {
      .MuiChip-root {
        height: auto;
        .MuiChip-label {
          padding: 0;
          font-size: 10px;
        }
      }
    }
    .MuiGrid-root {
      .gallery-box {
        height: 11vw;
        max-height: 204px;
        min-height: 180px;
      }
      .MuiCardMedia-root {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.MuiPagination-root ul.MuiPagination-ul {
  justify-content: center;
}

.MuiPaginationItem-page.Mui-selected {
  border-radius: 0;
}

.mypage-title > .MuiBox-root:first-child {
  width: calc(100% - 138px);
  max-width: calc(100% - 138px);
  @media (max-width: 766px) {
    width: 100%;
    max-width: 100%;
  }
  .MuiTypography-root {
    text-align: left;
  }
}

.info-area .info-detail {
  display: -webkit-box;
  display: -moz-box;
  display: flex;
}

.official-items {
  span.MuiTypography-root {
    color: #9d9d9d;
  }
}

.marketOwner .slick-slide .slider-inner > .slider-innertitle {
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 23px 28px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  word-break: break-all;
  color: #fff;
  background: #000;
  opacity: 0;
  transition: 0.5s;
  z-index: 100;
}
.marketOwner .slick-active .slider-inner > .slider-innertitle {
  opacity: 1;

  p {
    line-clamp: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
  }
}
.marketOwner .slick-active .slider-inner:hover .slider-innertitle {
  color: #5a2dda;
}

#root {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#root::-webkit-scrollbar {
  display: none;
}

// Sackbarのlinkに適用
.err-snack-link {
  color: white !important;
  text-decoration: underline;
}

.border-bottom {
  border-bottom: 1px solid #dedede;
}

.no-border {
  border: none !important;
}
